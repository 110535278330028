<template>
  <div>
    <b-card>
          <b-col
            md="12"
            class="b-col-custom"
          >
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="d-flex mr-2"
                @click="saveCategory()"
              >
                <span class="align-middle">{{ $t('Save') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-card>
    <CategoryExplorer
      :store-module-name="STORE_MODULE_NAME"
      :items.sync="respData"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BLink,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import storeModuleProduct from '@/store/services/eCommerce/product'
import storeModule from '@/store/services/eCommerce/productCategory'
import debounce from 'debounce'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CategoryExplorer from './CategoryExplorer.vue'

const STORE_MODULE_PRODUCT_NAME = 'product'
const STORE_MODULE_NAME = 'productCategory'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BAvatar,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VuePerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    PerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    CategoryExplorer,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      items: [],
      currentPage: 1,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    selectedCategories() {
      return store.state[STORE_MODULE_NAME].selectedCategories
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_PRODUCT_NAME)) store.registerModule(STORE_MODULE_PRODUCT_NAME, storeModuleProduct)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.get()
    this.view()
  },
  destroyed() {
    store.commit(`${STORE_MODULE_NAME}/ClearCategory`, [])
    if (store.hasModule(STORE_MODULE_PRODUCT_NAME)) store.unregisterModule(STORE_MODULE_PRODUCT_NAME)
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_PRODUCT_NAME, STORE_MODULE_NAME }
  },
  methods: {
    view() {
      store
        .dispatch(`${STORE_MODULE_PRODUCT_NAME}/view`, {
          id: this.$route.params.id,
        })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const productResult = result.data.data.productCategory
          console.log('productResult ', productResult)
          store.commit(`${STORE_MODULE_NAME}/GetCategory`, productResult.map(e => e._id))

        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: 0,
        searchQuery: '',
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.items = result.data.data
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveCategory() {
      const obj = {
        id: this.$route.params.id,
        productCategory: this.selectedCategories,
      }
      store
        .dispatch(`${STORE_MODULE_PRODUCT_NAME}/updateCategory`, obj)
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'CheckIcon',
              variant: 'success',
              text: this.$t('Success'),
            },
          })
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.b-col-custom {
  margin-top: 0.5rem;
}
</style>
