<template>
  <!-- Wrapper สำหรับคอลัมน์ปัจจุบัน และคอลัมน์ถัดไป (ถ้ามีการเลือก) -->
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <div class="d-flex explorer-column-wrapper">
        <!-- คอลัมน์ปัจจุบัน -->
        <div class="explorer-column">
          <!-- ตัวอย่างโชว์หัวข้อ ระบุระดับ (level) ไว้ได้ -->
          <!-- {{ isSelectedCategory }} -->
          <div
            class="d-flex align-items-center justify-content-between"
            style="padding-bottom: 10px;"
          >
            <h5>
              <!-- {{ level === 0 ? "Categories" : "Sub Categories" }} -->
              {{ $t("Categories") }}:
              {{
                selectedCategory !== null
                  ? `[${showFromCurrentLanguage(selectedCategory.name)}]`
                  : ""
              }}
            </h5>
            <div>
              <span>{{ `${$t("Total")} ${localItems.length} ${$t("entries")}` }}
              </span>
            </div>
          </div>
          <ul class="list-group cursor-move list-group-flush">
            <li
              v-if="localItems.length === 0"
              class="list-group-item"
            >
              ({{ $t("No data") }})
            </li>
          </ul>
          <vue-perfect-scrollbar
            ref="refCategory"
            :settings="perfectScrollbarSettings"
            style="height: 50vh;"
          >
            <draggable
              v-model="localItems"
              class="list-group cursor-move list-group-flush"
              tag="ul"
              @end="sort()"
            >
              <li
                v-for="(category, idx) in localItems"
                :key="category._id"
                class="list-group-item"
                :class="{
                  active:
                    selectedCategory !== null &&
                    selectedCategory._id === category._id,
                }"
                @click="selectItem(category)"
              >
                <!-- ชื่อ (เลือก logic แสดงภาษาเอง) -->

                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center justify-content-center">
                    <template>
                      <b-avatar
                        v-if="category.image"
                        size="25"
                        :src="category.image"
                        class="mr-1"
                      />
                      <b-avatar
                        v-else
                        size="25"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-1"
                      />
                    </template>

                    <div class="mr-1 align-items-center d-inline-flex">
                      {{ `[${idx + 1}]` }} {{ getCategoryName(category) }}
                    </div>
                  </div>
                </div>
              </li>
            </draggable>
          </vue-perfect-scrollbar>

        </div>

        <!-- คอลัมน์ลูก (Recursive) -->
        <!-- สร้างเสมอหากมีการ selectItem เป็นโฟลเดอร์ (selectedCategory.type === 'folder') -->
        <!-- แม้ children จะเป็นอาเรย์ว่าง ก็ยังแสดงคอลัมน์ถัดไปพร้อมข้อความ (No data) -->
        <CategoryExplorer
          v-if="selectedCategory"
          :store-module-name="storeModuleName"
          :items="selectedCategory.children"
          :is-selected-category="selectedCategory"
          :level="level + 1"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BButton, BOverlay } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'CategoryExplorer',
  components: {
    BAvatar,
    BButton,
    BOverlay,
    draggable,
    VuePerfectScrollbar,
  },
  model: {
    prop: 'items',
    event: 'change',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSelectedCategory: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSidebarActive: false,
      localItems: [...this.items],
      sidebarData: {},
      show: false,
      selectedCategory: null, // เก็บ category ที่ถูกคลิกในคอลัมน์นี้
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    respData() {
      return store.state[this.storeModuleName].respData != null
        ? store.state[this.storeModuleName].respData.data
        : []
    },
  },
  watch: {
    items(newVal) {
      console.log('items ', newVal)
      this.$nextTick(() => {
        this.localItems = [...newVal]

        // if (store.state[this.storeModuleName].selectedCategories.hasOwnProperty(this.level)) {
        //   const index = this.items.findIndex(e => e._id.toString() === store.state[this.storeModuleName].selectedCategories[this.level])
        //   if (index > -1) {
        //     this.selectedCategory = this.items[index]
        //   }
        // }
        // if (this.selectedCategory !== null) {
        //   const index = this.localItems.findIndex(
        //     e => e._id.toString() === this.selectedCategory._id.toString(),
        //   )
        //   if (index > -1) {
        //     this.selectedCategory = this.localItems[index]
        //   }
        // }else{

        // }
      })
    },
    respData(newVal) {
      if (this.level === 0) {
        this.selectAuto()
      }
    },
  },
  created() {
    this.selectAuto()
  },
  methods: {
    selectAuto() {
      if (store.state[this.storeModuleName].selectedCategories.hasOwnProperty(this.level)) {
        const index = this.items.findIndex(e => e._id.toString() === store.state[this.storeModuleName].selectedCategories[this.level])
        if (index > -1) {
          this.selectedCategory = this.items[index]
        }
      }
    },
    sort() {
      const obj = {
        isSelectedCategory: this.isSelectedCategory,
        dataCategory: this.localItems,
      }
      console.log(obj)
      this.show = true

      store
        .dispatch(`${this.storeModuleName}/saveSort`, obj)
        .then(result => {
          this.$emit('change', [...this.localItems])
          store
            .dispatch(`${this.storeModuleName}/get`, {
              currentPage: this.currentPage,
              pageSize: 0,
              searchQuery: '',
            })
            .then(result => {
              this.show = false
            })
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    selectItem(category) {
      // this.selectedCategory = null
      store.commit(`${this.storeModuleName}/SelectCategory`, category)
      if (this.selectedCategory === null) {
        this.$nextTick(() => {
          this.selectedCategory = category
        })
      } else {
        console.log(this.selectedCategory)
        console.log(category)
        if (this.selectedCategory._id === category._id) {
          this.$nextTick(() => {
            this.selectedCategory = null
          })
        } else {
          this.$nextTick(() => {
            this.selectedCategory = category
          })
        }
      }
    },
    // ฟังก์ชันเลือกชื่อภาษา (เช่น th ก่อน ถ้าไม่มีใช้ en)
    getCategoryName(category) {
      const thName = category.name.find(n => n.lang === 'th')
      if (thName) return thName.value
      const enName = category.name.find(n => n.lang === 'en')
      if (enName) return enName.value
      return '(Untitled)'
    },
  },
}
</script>

<style scoped>
/* สไตล์ตัวอย่าง ปรับตามชอบ */

/* Wrapper หลักของคอลัมน์ปัจจุบัน + คอลัมน์ลูก ซ้อนกันแนวนอน */
.explorer-column-wrapper {
  flex: 1;
  overflow-x: auto; /* เพื่อสามารถสกอลล์ในแนวนอนได้ หากคอลัมน์เยอะ */
}

/* แต่ละคอลัมน์ */
.explorer-column {
  min-width: 500px;
  max-width: 600px;
  border-left: 1px solid #ddd;
  padding: 10px;
}

/* คอลัมน์แรกไม่ต้องมีเส้นซ้าย */
.explorer-column:first-child {
  border-left: none;
}

/* list-group-item hover */
.list-group-item {
  cursor: pointer;
}
.list-group-item:hover {
  background-color: #f1f3f5;
}
</style>
